import React, {useEffect, useState} from 'react';
import AntPopover from "../AntPopover";
import Maximize from "../../../assets/imgs/icons/maximizeIcon.svg";
import Minimize from "../../../assets/imgs/icons/minimizeIcon.svg";
import CloseIcon from '../../../assets/imgs/icons/Close-blue.svg'
import Monitor from "../../../assets/imgs/icons/monitorIcon.svg";
import AMinus from "../../../assets/imgs/icons/aMinus.svg";
import APlus from "../../../assets/imgs/icons/aPlus.svg";
import Edit from "../../../assets/imgs/icons/EditIcon.svg";
import "./style.css";
import {useTranslation} from "react-i18next";
import MapScreenShotPopup from "../MapScreenShotPopup";

const MapScreenShotPopupView = ({
                                    open,
                                    onClose,
                                    mapStateSingleData,
                                    saveScreenShot,
                                    screenshotUrl,
                                    onSuccess,
                                    title,
                                    isEditMode,
                                    data,
                                    isEditModePopup,
                                    globalLoading,
                                    ...rest
                                }) => {
    const {t} = useTranslation()

    const [isMinimize, setIsMinimize] = useState(false);
    const [fontSize, setFontSize] = useState(16);
    const [isOpenEditing, setIsOpenEditing] = useState(false);

    const screenWidth = window.innerWidth;


    const handleMinimize = () => setIsMinimize((prevState) => !prevState);

    const configureFontSize = (type) => {
        if (type === 'plus' && fontSize < (screenWidth < 1024 ? 26 : 20)) {
            setFontSize(fontSize + 2);
        }
        if (type === 'minus' && fontSize > (screenWidth < 1024 ? 20 : 14)) {
            setFontSize(fontSize - 2);
        }
    };

    const content = () => (
        <div
            className={`map-screenshot-popup-edit ${isMinimize ? "map-screenshot-popup-edit--minimized" : "map-screenshot-popup-edit--expanded"}`}>
            <div className="map-screenshot-popup-edit__header">
                <div style={{width: '80%'}}>
                    {/*    <span*/}
                    {/*        className="map-screenshot-popup-edit__header-title-first"*/}
                    {/*    >*/}
                    {/*    ԱՄ*/}
                    {/*</span>*/}
                    <span className="map-screenshot-popup-edit__header-title">{mapStateSingleData?.title}</span>
                </div>
                <div className="map-screenshot-popup-edit__header-buttons">
                    {isMinimize ? (
                        <button onClick={handleMinimize}>
                            <img style={{width: '30px', height: '30px'}} src={Maximize} alt="Maximize"/>
                        </button>
                    ) : (
                        <button onClick={handleMinimize}>
                            <img style={{width: '26px', height: '26px'}} src={Minimize} alt="Minimize"/>
                        </button>
                    )}
                    <button onClick={onClose}>
                        <img style={{width: '30px', height: '30px'}} src={CloseIcon} alt="Close"/>
                    </button>
                </div>
            </div>
            {!isMinimize && (
                <div>
                    <p
                        className="map-screenshot-popup-edit__description"
                        style={{
                            fontSize: `${fontSize}px`,
                            lineHeight: `${fontSize >= 14 && fontSize < 22 ? 24 : 30}px`
                        }}>
                        {mapStateSingleData?.description}
                    </p>
                    <div className="map-screenshot-popup-edit__actions">
                        <div className="map-screenshot-popup-edit__action">
                            <button
                                disabled={fontSize >= (screenWidth < 1024 ? 26 : 20)}
                                className="map-screenshot-popup-edit__icon-container"
                                onClick={() => configureFontSize('plus')}
                            >
                                <img className="map-screenshot-popup-edit__icon-minus" src={APlus} alt="Plus"/>
                            </button>
                            <button
                                disabled={fontSize <= (screenWidth < 1024 ? 20 : 14)}
                                className="map-screenshot-popup-edit__icon-container"
                                onClick={() => configureFontSize('minus')}
                            >
                                <img className="map-screenshot-popup-edit__icon-minus" src={AMinus} alt="Minus"/>
                            </button>
                            <div className="map-screenshot-popup-edit__icon-container">
                                <img className="map-screenshot-popup-edit__icon" src={Monitor} alt="Monitor"/>
                            </div>
                            <MapScreenShotPopup
                                onOpenChange={(newOpen) => {
                                    setIsOpenEditing(newOpen)
                                }}
                                onClose={() => setIsOpenEditing(false)}
                                open={isOpenEditing}
                                trigger="click"
                                saveScreenShot={saveScreenShot}
                                screenshotUrl={mapStateSingleData?.imageUrl}
                                title={mapStateSingleData?.title}
                                description={mapStateSingleData?.description}
                                data={data}
                                onSuccess={() => setIsOpenEditing(false)}
                                isEditMode
                                overlayClassName="map-screenshot-popup__overlay"
                            >
                                <div className="map-screenshot-popup-edit__icon-container">
                                    <img className="map-screenshot-popup-edit__icon" src={Edit} alt="Edit"/>
                                </div>
                            </MapScreenShotPopup>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );


    useEffect(() => {
        const handleResize = () => {
            const newScreenWidth = window.innerWidth;
            if (newScreenWidth < 1024 && fontSize === null) {
                setFontSize(20);
            } else if (fontSize === null) {
                setFontSize(14);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fontSize])

    if (globalLoading) {
        return null
    }

    return (
        <AntPopover
            arrow={false}
            open={open}
            trigger="click"
            content={content}
            {...rest}
        />
    );
};

export default MapScreenShotPopupView;
