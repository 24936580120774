import React, {useEffect, useState} from 'react';
import AntPopover from "../AntPopover";
import Maximize from "../../../assets/imgs/icons/maximizeIcon.svg";
import Minimize from "../../../assets/imgs/icons/minimizeIcon.svg";
import CloseIcon from '../../../assets/imgs/icons/Close-blue.svg'
import Monitor from "../../../assets/imgs/icons/monitor.svg";
import {TextInput} from "../TextInput";
import {Button} from "../Button";
import "./style.css";
import {checkValidationErrors} from "../../../pages/map/form-validation";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {parse} from "flatted";
import {
    createScreenShotAPI, editScreenDataAPI
} from "../../../store/actions/mapStateAction";
import {connect} from "react-redux";
import {getScreenShotSendDataST, getTopicStaticTitleST} from "../../../store/selectors";
import {uploadImage} from "../../../utils/helpers";

const MapScreenShotPopup = ({
                                open,
                                onClose,
                                saveScreenShot,
                                screenshotUrl,
                                lessonNavigationData,
                                topicNavigation,
                                title,
                                description,
                                isEditMode,
                                createScreenShotAPI,
                                editScreenDataAPI,
                                data,
                                getTopicStaticTitleST,
                                getScreenShotSendDataST,
                                onSuccess,
                                globalLoading,
                                screenShotBlob,
                                handleCaptureClick,
                                ...rest
                            }) => {
    const {t} = useTranslation();
    let [searchParams] = useSearchParams();

    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [apiErrorMsg, setApiErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [isMinimize, setIsMinimize] = useState(false);
    const [initialFields, setInitialFields] = useState({title: "", description: ""});
    const screenWidth = window.innerWidth;

    const handleMinimize = () => setIsMinimize((prevState) => !prevState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validationErrors = checkValidationErrors(fields, t);
        if (validationErrors) {
            setLoading(false);
            setErrors(validationErrors);
            return;
        }
        try {
            if (isEditMode) {
                await editScreenDataAPI(
                    data.languageId,
                    parse(getScreenShotSendDataST).id,
                    fields?.title,
                    data,
                    null,
                    searchParams.get(`topicID`),
                    searchParams.get(`lessonID`),
                    fields?.description,
                    screenshotUrl
                );
            } else {
                await uploadImage(screenShotBlob)
                    .then(resp => {
                        data.imageUrl = resp?.mediaInfo?.optimized
                        data.mediaId = resp?.id
                    })
                await createScreenShotAPI(
                    data.languageId,
                    fields?.title || topicNavigation.current?.title || lessonNavigationData.current?.title,
                    data,
                    searchParams.get(`topicID`),
                    searchParams.get(`lessonID`),
                    fields?.description,
                    screenshotUrl
                );
            }
            onSuccess();
        } catch (error) {
            setApiErrorMsg(t('errorMessage'));
        } finally {
            setLoading(false);
        }
    };


    const isDisabled = () => {
        if (!isEditMode) return false;
        return (
            fields.title === initialFields.title &&
            fields.description === initialFields.description
        );
    };

    const content = () => (
        <div
            className={`map-screenshot-popup ${
                isMinimize ? "map-screenshot-popup--minimized" : "map-screenshot-popup--expanded"
            }`}
        >
            <div className="map-screenshot-popup__header">
                {isMinimize ? (
                    <Button
                        text={t('screenShot.save')}
                        action={handleSubmit}
                        className="map-screenshot-popup__submit-button"
                        loading={loading}
                        disabled={isDisabled()}
                    />
                ) : (
                    <>
                    {isEditMode ?  <h1>{t("screenShot.edit_map")}</h1> : <h1>{t("screenShot.new_map")}</h1>}
                    </>
                )}
                <div className="map-screenshot-popup__header-buttons">
                    {isMinimize ? (
                        <button onClick={handleMinimize}>
                            <img style={{width: "30px", height: "30px"}} src={Maximize} alt="Maximize"/>
                        </button>
                    ) : (
                        <button onClick={handleMinimize}>
                            <img style={{width: "26px", height: "26px"}} src={Minimize} alt="Minimize"/>
                        </button>
                    )}
                    <button onClick={onClose}>
                        <img style={{width: "30px", height: "30px"}} src={CloseIcon} alt="Close"/>
                    </button>
                </div>
            </div>
            {!isMinimize && (
                <form className="map-screenshot-popup__form" onSubmit={handleSubmit}>
                    <div className="map-screenshot-popup__preview" data-is-loading={!screenshotUrl}>
                        {screenshotUrl && <img src={screenshotUrl} alt="Screenshot Preview"/>}
                    </div>
                    <div className="map-screenshot-popup__fields">
                        <div>
                            <label className="map-screenshot-popup__label">
                                {t("screenShot.name")}
                                <TextInput
                                    className="map-screenshot-popup__textarea fixed-size-textarea"
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            title: e.target.value.length > 70 ? e.target.value.slice(0, 70) : e.target.value,
                                        })
                                    }
                                    value={fields["title"] || ""}
                                />
                                {errors["title"] && (
                                    <span className="map-screenshot-popup__error">{errors["title"]}</span>
                                )}
                                <span className="map-screenshot-popup__counter">
                                    {fields["title"]?.length || 0} / 70
                                </span>
                            </label>
                        </div>
                        <div>
                            <label className="map-screenshot-popup__label map-screenshot-popup__label--description">
                                {t("screenShot.description")}
                                <textarea
                                    className="map-screenshot-popup__textarea fixed-size-textarea"
                                    rows="5"
                                    value={fields["description"] || ""}
                                    onChange={(e) =>
                                        setFields({
                                            ...fields,
                                            description: e.target.value.length > 1000
                                                ? e.target.value.slice(0, 1000)
                                                : e.target.value,
                                        })
                                    }
                                />
                                {errors["description"] && (
                                    <span className="map-screenshot-popup__error">{errors["description"]}</span>
                                )}
                                <span className="map-screenshot-popup__counter">
                                    {fields["description"]?.length || 0} / 1000
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="map-screenshot-popup__actions">
                        <div className="map-screenshot-popup__action">
                            <div className="map-screenshot-popup__icon-container">
                                <img className="map-screenshot-popup__icon" src={Monitor} alt="Monitor"/>
                            </div>
                            <span>{t("screenShot.add_to_presentation")}</span>
                        </div>
                    </div>
                    <div className="map-screenshot-popup__submit">
                        <Button
                            text={t('screenShot.save')}
                            type="submit"
                            className="map-screenshot-popup__submit-button"
                            loading={loading}
                            disabled={isDisabled()}
                        />
                    </div>
                </form>
            )}
        </div>
    );

    useEffect(() => {
        if (!open) {
            setFields({});
            setErrors({});
            setApiErrorMsg("");
            setLoading(false);
        } else {
            const initialState = {
                title: title || `${t('lesson')} - ${lessonNavigationData?.current?.title} | ${t('topic')} - ${topicNavigation?.current?.title}` || "",
                description: description || "",
            };
            setFields(initialState);
            setInitialFields(initialState);
        }
    }, [description, lessonNavigationData, open, t, title, topicNavigation]);

    if (globalLoading) {
        return null
    }

    return (
        <AntPopover
            placement="left"
            overlayStyle={{
                position: "absolute", top: screenWidth < 3000 ? 52 : 77, right: 0
            }}
            arrow={false}
            open={open}
            trigger="click"
            content={content}
            {...rest}
        />
    );
};


const mapStateTopProps = (state) => ({
    getTopicStaticTitleST: getTopicStaticTitleST(state),
    getScreenShotSendDataST: getScreenShotSendDataST(state),
});

const mapDispatchToProps = {
    createScreenShotAPI,
    editScreenDataAPI,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(MapScreenShotPopup);
