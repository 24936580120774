export const checkValidationErrors = (fields, t) => {
  let errors = {};
  let hasError = false;

  // Title validation
  if (!fields["title"] || fields["title"].trim() === "") {
    hasError = true;
    errors["title"] = t('errors.requiredField');
  }

  return hasError ? errors : null;
};
