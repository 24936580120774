import React from 'react';
import {Popover} from "antd";

const AntPopover = ({...rest}) => {
    return (
        <Popover {...rest}/>
    );
};

export default AntPopover;
